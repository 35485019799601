import { Locale, type LocalizedString } from "../Locale.js";
import { PriorityLabel } from "../PriorityLabel.js";

export const priorityLabelNameMap = {
	[PriorityLabel.Favorite]: {
		[Locale.cs]: "Nejoblíbenější",
	},
	[PriorityLabel.New]: {
		[Locale.cs]: "Novinka",
	},
} satisfies Record<PriorityLabel, LocalizedString>;
